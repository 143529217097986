<template>
    <div>
        <div>
            <b-card>
                <div id="container-map" v-if="status_mapa">
                    <l-map :center="center" :zoom="10" ref="mymap">
                        <l-control-layers :collapsed="true" :position="layersPosition" :sort-layers="true">
                        </l-control-layers>
                        <l-tile-layer :key="tileProvider.name" :name="tileProvider.name" :url="tileProvider.url" :visible="tileProvider.visible" layer-type="base" v-for="tileProvider in tileProviders">
                        </l-tile-layer>
                        <l-control position="topleft" v-if="showButtons === true">
                          <b-button v-if="fitBounds == false"  @click="fitBounds = true" class="btn-icon btn-map rounded-circle" variant="primary" :title="$t('Labels.Follow')" v-b-tooltip.hover="{ variant: 'primary', placement: 'right' }" >
                            <feather-icon icon="EyeIcon"/>
                          </b-button>
                          <b-button v-else @click="fitBounds = false" class="btn-icon btn-map rounded-circle" variant="primary" :title="$t('Labels.StopFollow')" v-b-tooltip.hover="{ variant: 'primary', placement: 'right' }" >
                            <feather-icon icon="EyeOffIcon" :width="18" :height="18"/>
                          </b-button>
                          <b-button
                            v-if="tracing.status !== 'F' && tracing.status !== 'P'"
                            variant="success"
                            class="btn-icon rounded-circle btn-map"
                            @click="confirmChangeStatus('P')"
                            :title="$t('Labels.Play')" v-b-tooltip.hover="{ variant: 'success', placement: 'right' }"
                          >
                            <feather-icon icon="PlayIcon" :width="18" :height="18"/>
                          </b-button>
                          <b-button
                            v-if="tracing.status === 'P'"
                            variant="primary"
                            class="btn-icon rounded-circle btn-map"
                            @click="confirmChangeStatus('S')"
                            :title="$t('Labels.Pause')" v-b-tooltip.hover="{ variant: 'primary', placement: 'right' }"
                          >
                            <feather-icon icon="PauseIcon" :width="18" :height="18"/>
                          </b-button>
                          <b-button
                            v-if="tracing.status === 'P'"
                            variant="danger"
                            class="btn-icon rounded-circle btn-map"
                            @click="confirmChangeStatus('F')"
                            :title="$t('Labels.Stop')" v-b-tooltip.hover="{ variant: 'danger', placement: 'right' }"
                          >
                            <feather-icon icon="SquareIcon" :width="18" :height="18"/>
                          </b-button>
                          <b-button
                            variant="primary"
                            class="btn-icon rounded-circle btn-map"
                            :to="`/tracings/members/${uuid}`"
                            :title="$t('Labels.Members')" v-b-tooltip.hover="{ variant: 'primary', placement: 'right' }"
                          >
                            <feather-icon icon="UsersIcon" :width="18" :height="18"/>
                          </b-button>
                          <b-button @click="showConfig = !showConfig" class="btn-icon btn-map rounded-circle" variant="secondary" :title="$t('Labels.Config')" v-b-tooltip.hover="{ variant: 'secondary', placement: 'right' }" v-if="$store.state.session.AppActiveUser().userRole !== 'TP'">
                            <feather-icon icon="SettingsIcon" />
                          </b-button>
                          <b-button
                            variant="danger"
                            class="btn-icon rounded-circle btn-map"
                            @click="confirmDelete()"
                            :title="$t('Labels.Delete')" v-b-tooltip.hover="{ variant: 'danger', placement: 'right' }"
                          >
                            <feather-icon icon="TrashIcon" :width="18" :height="18"/>
                          </b-button>
                        </l-control>
                        <l-control position="verticalcenterright" class="leaflet-topcenter">
                          <b-badge variant="danger">{{$t('Labels.Distance')}} : {{tracing.distance_tracing}}mt</b-badge>
                        </l-control>
                        <l-control position="topright">
                          <b-card v-if="showConfig">
                            <b-row>
                              <b-col cols="12">
                                <b-button
                                  variant="flat-primary"
                                  class="btn-icon float-right btn-map rounded-circle"
                                  @click="showConfig = false"
                                >
                                  <feather-icon icon="XIcon" />
                                </b-button>
                              </b-col>
                            </b-row>
                            <h4>
                                {{$t("Labels.Alerts")}}
                            </h4>
                            <b-form
                              class=""
                              @submit.prevent
                            >
                            <validation-observer ref="tracingValidation">
                              <b-row>
                                <b-col cols="12">
                                  <b-form-group
                                    :label="$t('Labels.Name')"
                                    label-for="name"
                                  >
                                    <validation-provider
                                      #default="{ errors }"
                                      name="name"
                                      rules="required"
                                    >
                                      <b-form-input id="name" v-model="name" :state="errors.length > 0 ? false:null" name="name" :placeholder="$t('Labels.Name')" maxlength="254"/>
                                      <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col cols="12">
                                  <b-form-group
                                    :label="$t('Labels.Distance')+' (mt)'"
                                    label-for="distance"
                                  >
                                    <b-form-spinbutton
                                      id="distance"
                                      v-model="distance"
                                      name="distance"
                                      min="1"
                                      max="10000"
                                    />
                                  </b-form-group>
                                </b-col>
                              </b-row>
                            </validation-observer>
                            <b-row>
                              <b-col cols="12">
                                <b-button
                                  type="submit"
                                  variant="primary"
                                  block
                                  @click="validationForm"
                                  class="mt-1"
                                >
                                  {{$t("Labels.Save")}}
                                </b-button>
                              </b-col>
                            </b-row>
                            </b-form>
                          </b-card>
                        </l-control>
                        <v-marker-cluster>
                            <l-marker :draggable="false" :key="item.id" :lat-lng.sync="item.position" :visible="item.visible" v-for="item in markers">
                                <l-icon :icon-size="[40,40]" >
                                  <avatarMarker
                                    :label="item.order"
                                    :status="item.status"
                                  />
                                </l-icon>
                                <l-popup>
                                    <div class="popup">
                                      <valueItem
                                        :value="item.name"
                                        :description="$t('Labels.Alias')"
                                        img="pointtracker"
                                      />
                                      <valueItem
                                        :value="item.distance"
                                        :description="$t('Labels.Distance')"
                                        img="georeference"
                                        unit="mt"
                                      />
                                </div>
                                </l-popup>
                            </l-marker>
                        </v-marker-cluster>
                    </l-map>
                </div>
            </b-card>
        </div>
    </div>
</template>
<script>
import {
  LMap,
  LTileLayer,
  LMarker,
  LIcon,
  LPopup,
  LControlLayers,
  LControl
} from 'vue2-leaflet'
import L from 'leaflet'
import Vue2LeafletMarkercluster from 'vue2-leaflet-markercluster/Vue2LeafletMarkercluster'
import valueItem from '@core/spore-components/valueItem/valueItem.vue'
import avatarMarker from '@core/spore-components/avatarMarker/avatarMarker.vue'
import request from '@/libs/request/index'
import 'leaflet/dist/leaflet.css'
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css'
import 'leaflet-fullscreen/dist/Leaflet.fullscreen'
import { empty } from '@/libs/tools/helpers'
import {
  BCard,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BForm,
  BButton,
  BFormSpinbutton,
  BFormInput
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

const tileProviders = [
  {
    name: 'Gris',
    visible: true,
    url: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png'
  },
  {
    name: 'Calles',
    visible: false,
    url: 'https://{s}.tile.openstreetmap.fr/osmfr//{z}/{x}/{y}.png'
  },
  {
    name: 'Satelite',
    visible: false,
    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png'
  }
]

export default {
  name: 'mapa',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LIcon,
    LControlLayers,
    'v-marker-cluster': Vue2LeafletMarkercluster,
    valueItem,
    BCard,
    avatarMarker,
    LControl,
    BButton,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BFormSpinbutton,
    BFormInput,
    ValidationProvider,
    ValidationObserver
  },
  data () {
    return {
      tracing: {},
      tileProviders,
      layersPosition: 'topright',
      center: [20.662396323989693, -103.37640969334421],
      staticAnchor: [12, 27],
      markers: [],
      status_mapa: false,
      uuid: this.$route.params.uuid,
      showButtons: false,
      fitBounds: true,
      showConfig: false,
      distance: 0,
      name: '',
      required
    }
  },
  methods: {
    async getMapa () {
      const params = {
        url: `tracing/${this.uuid}`,
        method: 'GET'
      }
      await request(params).then(data => {
        this.tracing = data.data.data[0]
        this.distance = this.tracing.distance_tracing
        this.name = this.tracing.name
        if (!empty(data.data.data[0].tracing_trackers)) {
          data = data.data.data[0].tracing_trackers
        } else {
          data = []
        }
        const positions = []
        for (let i = 0; i < data.length; i++) {
          if (!empty(data[i].tracker.lat) && data[i].tracker.lat !== '0' && !empty(data[i].tracker.long) && data[i].tracker.long !== '0') {
            const lat = data[i].tracker.lat
            const lng = data[i].tracker.long
            positions.push([lat, lng])
            this.markers.push({
              order: data[i].order,
              position: { lat: lat, lng: lng },
              name: data[i].tracker.name,
              status: parseInt(data[i].tracker.distance_tracker || 0) > parseInt(this.tracing.distance_tracing) ? 'A' : 'S',
              distance: data[i].tracker.distance_tracker || 0,
              id: data[i].tracker_uuid,
              draggable: false,
              visible: true
            })
          }
        }
        this.status_mapa = true

        const app = this
        setTimeout(function () {
          const maps = app.$refs.mymap.mapObject
          maps.addControl(new L.Control.Fullscreen({
            position: 'topleft'
          }))
          maps.fitBounds(positions)
          app.showButtons = true
        }, 1000)
      }).catch(() => {
        this.markers = []
      })
    },
    updateTracing (payload) {
      if (!empty(payload.lat) && payload.lat !== '0' && !empty(payload.long) && payload.long !== '0') {
        const tracker = this.markers.filter(marker => marker.id === payload.uuid)[0]
        if (!empty(tracker)) {
          this.markers = this.markers.filter(marker => marker.id !== payload.uuid)
          tracker.status = parseInt(payload.distance || 0) > parseInt(this.tracing.distance_tracing) ? 'A' : 'S'
          tracker.position = { lat: payload.lat, lng: payload.long }
          tracker.distance = payload.distance || 0
          this.markers.push(tracker)
          if (this.fitBounds) {
            this.setFitBounds()
          }
        }
      }
    },
    setFitBounds () {
      const positions = []
      this.markers.forEach(item => {
        positions.push([item.position.lat, item.position.lng])
      })
      const maps = this.$refs.mymap.mapObject
      maps.fitBounds(positions)
    },
    confirmDelete () {
      this.$swal({
        title: this.$t('Messages.ConfirmDeleteGroup'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Messages.Yes'),
        cancelButtonText: this.$t('Messages.Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(async result => {
        if (result.value) {
          const params = {
            url: `tracing/${this.uuid}`,
            method: 'DELETE'
          }
          await request(params).then(response => {
            this.$router.push('/tracings')
          })
        }
      })
    },
    async confirmChangeStatus (status) {
      const params = {
        url: `tracing/${this.uuid}`,
        method: 'PUT',
        params: {
          status: status
        }
      }
      await request(params).then(response => {
        this.tracing.status = status
      })
    },
    async saveTracing () {
      const params = {
        url: `tracing/${this.uuid}`,
        method: 'PUT',
        params: {
          distance: this.distance.toString(),
          name: this.name
        }
      }
      await request(params).then(response => {
        this.tracing.distance_tracing = this.distance
        this.tracing.name = this.name
      })
    },
    validationForm () {
      this.$refs.tracingValidation.validate().then(success => {
        if (success) {
          this.saveTracing()
        }
      })
    }
  },
  mounted () {
    this.$socket.open()
    this.sockets.subscribe(`tracings/${this.uuid}`, this.updateTracing)
  },
  created () {
    this.getMapa()
  },
  beforeDestroy () {
    this.sockets.unsubscribe(`tracings/${this.uuid}`)
    this.$socket.close()
  }
}
</script>
<style lang="scss" scoped>
  @import "~leaflet.markercluster/dist/MarkerCluster.css";
  @import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
  @import "@/assets/scss/pages/MapTracings.scss";
</style>
