<template>
  <svg xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 33.483 42.868"
    aria-labelledby="Contact Icon"
    role="presentation"
  >
    <g id="Group_2797" data-name="Group 2797" transform="translate(-542.627 -136.627)">
      <g id="Group_2796" data-name="Group 2796" transform="translate(542.627 136.627)">
        <g id="Group_2793" data-name="Group 2793" transform="translate(6.669)">
          <g id="Group_2792" data-name="Group 2792">
            <path id="Path_1904" data-name="Path 1904" d="M559.055,136.627c-5.554,0-10.073,4.791-10.073,10.68s4.519,10.68,10.073,10.68,10.073-4.791,10.073-10.68S564.609,136.627,559.055,136.627Z" transform="translate(-548.981 -136.627)" :fill="iconColor"/>
          </g>
        </g>
        <g id="Group_2795" data-name="Group 2795" transform="translate(0 24.702)">
          <g id="Group_2794" data-name="Group 2794">
            <path id="Path_1905" data-name="Path 1905" d="M576.079,175.378a16.235,16.235,0,0,0-16.206-15.215h-1.007a16.239,16.239,0,0,0-16.24,16.238h0a1.928,1.928,0,0,0,1.927,1.928h29.629a1.928,1.928,0,0,0,1.927-1.928h0C576.11,176.058,576.1,175.716,576.079,175.378Z" transform="translate(-542.627 -160.163)" :fill="iconColor"/>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    iconColor: {
      type: String,
      default: '#9383db'
    }
  }
}
</script>
