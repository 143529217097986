<template>
  <svg xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 43.394 57.637"
    aria-labelledby="Fire Icon"
    role="presentation"
  >
    <g id="Group_2790" data-name="Group 2790" transform="translate(0 0)">
      <path id="Path_1901" data-name="Path 1901" d="M149,186.933c-2.645-3.007-5.291-6.133-6.975-9.86a22.369,22.369,0,0,1-1.323-12.867,27.758,27.758,0,0,1,5.532-11.9,11.464,11.464,0,0,0,2.886,8.417,20,20,0,0,1,4.569-16.593c3.848-4.569,9.139-7.576,14.669-9.621-2.765,2.886-3.126,7.456-2.043,11.3s3.366,7.215,5.41,10.7,3.968,7.094,4.089,11.063a38.644,38.644,0,0,0,3.007-6.373,12.6,12.6,0,0,0,.481-6.975,17.88,17.88,0,0,1,3.968,8.658,36.416,36.416,0,0,1,.12,10.222,25.57,25.57,0,0,1-3.488,10.7,20.076,20.076,0,0,1-10.461,7.817C161.986,192.945,154.651,191.862,149,186.933Z" transform="translate(-140.297 -134.504)" :fill="iconColor"/>
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    iconColor: {
      type: String,
      default: '#FC446E'
    }
  }
}
</script>
