<template>
  <svg xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 39.32 39.311"
    aria-labelledby="Medic Icon"
    role="presentation"
  >
    <path id="Path_4400" data-name="Path 4400" d="M75.65,222.059a3.224,3.224,0,0,1-3.222,3.222H66.2a1.02,1.02,0,0,0-1.02,1.02v6.229a3.225,3.225,0,0,1-3.222,3.222H50.026A3.224,3.224,0,0,1,46.8,232.53V226.3a1.023,1.023,0,0,0-1.02-1.02H39.552a3.224,3.224,0,0,1-3.222-3.222V210.137a3.227,3.227,0,0,1,3.222-3.222h6.221a1.02,1.02,0,0,0,1.02-1.02v-6.232a3.224,3.224,0,0,1,3.222-3.222H61.943a3.228,3.228,0,0,1,3.222,3.222v6.232a1.02,1.02,0,0,0,1.02,1.02h6.244a3.225,3.225,0,0,1,3.222,3.222Z" transform="translate(-36.33 -196.441)" :fill="iconColor"/>
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    iconColor: {
      type: String,
      default: '#00cf59'
    }
  }
}
</script>
